<template>
  <div class='voting-answer-completed standard-transition'
    :class='selectedAnswerStyle'>
    <div
      class='h-2 rounded-full absolute top-0 right-0'
      :style='progressBarStyle'>
    </div>
    <div class='flex flex-row justify-between items-center'>
      <h3 class='block text-sm text-gray-900 uppercase'>
        a{{index + 1}}
      </h3>
      <h3 class='block font-normal text-sm text-gray-500 capitalize text-right'>
        Responses
      </h3>
    </div>
    <div class='flex flex-row justify-between items-start'>
      <div class='text-sm lg:text-normal whitespace-normal '>{{ choiceData.text }}</div>
      <div class='text-sm lg:text-normal text-gray-900 whitespace-normal'>{{ responseCount || 0 }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SessionVotingAnswerFinished',
  props: {
    myAnswerId: {
      type: Number,
      required: false,
      default: 0,
    },
    index: {
      type: Number,
      required: true,
    },
    choiceData: {
      type: Object,
      required: true,
    },
    choiceResultData : {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: false,
    }
  },  
  computed: {
    selectedAnswerStyle () {
      return this.myAnswerId === this.choiceData.id ? 'selectedAnswerClass' : ''
    },
    totalResponseCount () {
      return this.choiceResultData.reduce((total, choice) => total + choice.choicedCount, 0)
    },
    responseCount () {
      let aa = this.choiceResultData.findIndex(choiceResult => choiceResult.choiceId === this.choiceData.id)
      let num = 0
      if (aa > -1) {
        num = this.choiceResultData[aa].choicedCount
      } else {
        num = 0
      } 
      return num
    },
    responsePercentage () {
      return (this.responseCount / this.totalResponseCount) * 100
    },
    barColor () {
      return  this.color || 'var(--eventMainColor)'
    },
    progressBarStyle () {
      return `width: ${this.responsePercentage || 0}%; background-color:${this.barColor};`
    },
  },
  methods: {
  },
}
</script>

<style lang='scss' scoped>
  .voting-answer-completed {
    @apply py-6 border rounded-md my-4 px-4 relative overflow-hidden opacity-90;
  }

  .voting-answer-completed.selectedAnswerClass {
    @apply shadow-xl font-semibold text-gray-900 opacity-100;
    background-color: var(--lightestColor);
    border-color: var(--lightColor);
  }

</style>
