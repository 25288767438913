<template>
  <div>
    <div class='px-4 pt-4 pb-4 relative'>
      <button 
        @click='goToSessionVotings'
        :class='themeTextColorStyle'>
        <arrow-narrow-left-icon class='inline-block h-4' /> 
        See All Questions
      </button>
      <h1 class='text-xl font-medium text-gray-900 mt-8'>{{sessionTitle}}</h1>
      <content-background-cover
        background-color='#FAFAFA'
        class='z-0 border-b' />
    </div>
    <div class='px-2 lg:px-0 pt-8 pb-16'>
      <div class='py-0.5 flex flex-row justify-start items-start gap-x-4 pl-2 mb-12'>
        <label class='block flex-shrink-0 flex-grow-0 text-center font-medium text-gray-800 w-10 h-10 py-2 rounded-md bg-gray-100 uppercase'>q</label>
        <p class='text-sm lg:text-normal font-semibold uppercase text-gray-900 whitespace-pre-line pt-2.5'>{{ questionText }}</p>
      </div>
      <component v-for='(choice, index) in sessionVotingChoices' 
        :key='choice.id'
        :is='choiceComponent'
        :index='index'
        :choice-data='choice'
        :choice-result-data='sessionVotingChoiceResults'
        :my-answer-id='myAnswerId'
        @select-answer='selectAnswer' />
    </div>
  </div>
</template>

<script>
import ContentBackgroundCover       from '@/components/ContentBackgroundCover.vue'
import SessionVotingAnswerOpen      from '@/components/SessionVotingAnswerOpen.vue'
import SessionVotingAnswerFinished  from '@/components/SessionVotingAnswerFinished.vue'
import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'
import { mapGetters, mapActions } from 'vuex'
import { mapFields }           from 'vuex-map-fields'

export default {
  name: 'SessionVoting',
  components: {
    ArrowNarrowLeftIcon,
    SessionVotingAnswerOpen,
    SessionVotingAnswerFinished,
    ContentBackgroundCover,
  },
  data () {
    return {
      selectedQuestionId: 0,
    }
  },
  computed: {
    ...mapFields('sessionVoting', [
      'selectedVotingQuestion',
      'selectedVotingQuestion.me.sessionVotingResult.choiceId',
      'selectedVotingQuestion.me.sessionVotingResult.choiceText',
    ]),
    ...mapGetters('events', [
      'themeTextColorStyle',
      'showingEventId'
    ]),
    sessionTitle () {
      return this.selectedVotingQuestion ? this.selectedVotingQuestion.session.title : ''
    },
    questionText () {
      return this.selectedVotingQuestion ? this.selectedVotingQuestion.question : ''
    },
    sessionVotingChoices () {
      return this.selectedVotingQuestion ? this.selectedVotingQuestion.choices : []
    },
    sessionVotingChoiceResults () {
      return this.selectedVotingQuestion ? this.selectedVotingQuestion.sessionVotingResults : []
    },
    myAnswerId () {
      return this.selectedVotingQuestion ? this.selectedVotingQuestion.me.sessionVotingResult.choiceId : 0
    },
    sessionVotingId () {
      return parseInt(this.$route.query.session_voting_id)
    },
    choiceComponent () {
      return (this.selectedVotingQuestion.status === 'finished')  ? 'session-voting-answer-finished' : 'session-voting-answer-open'
    },
  },
  methods: {
    ...mapActions('sessionVoting', [
      'getSessionVotingQuestionData',
      'getSessionVotingQuestionResultData',
      'postSessionVotingResult',
    ]),
    selectAnswer (choice) {
      this.choiceId = choice.id
      this.choiceText = choice.text
      let data = {
        sessionVotingId: this.sessionVotingId,
        choiceId: choice.id,
        choiceText: choice.text,
        eventId: this.showingEventId,
        sessionId: this.selectedVotingQuestion.sessionId
      }
      this.postSessionVotingResult(data)
        .then(resp => {
          this.$message({
            message: `Your response (${resp.session_voting_result.choice_text}) has been successfully recorded.`,
            type:    'success'
          })
        })
        .catch(err => {
          if (err.status === 409) {
            this.$message({
              message: `You have already responded to this question.`,
              type:    'error'
            })
            
            this.getSessionVotingQuestionData(this.selectedQuestionId)
          } else {
            this.$message({
              message: `There was an error recording your response. Please try again.`,
              type:    'error'
            })
          }
        })
    },
    goToSessionVotings () {
      let query = Object.assign({}, this.$route.query)
      delete query.session_voting_id
      this.$router.push({ name: 'SessionVotings', query: query})
    },
  },
  mounted () {
    if (this.sessionVotingId) {
      this.selectedQuestionId = this.sessionVotingId
      this.getSessionVotingQuestionData(this.selectedQuestionId)
    }
  },
}
</script>

