<template>
  <div class='voting-answer standard-transition'
    :class='selectedAnswerStyle'
    @click='selectAnswer'>
    <label class='block flex-shrink-0 flex-grow-0 text-center font-medium text-gray-800 w-10 h-10 py-2 uppercase my-2'>
      a{{index + 1}}
    </label>
    <p class='text-sm lg:text-normal text-gray-900'>{{ choiceData.text }}</p>
  </div>
</template>

<script>

export default {
  name: 'SessionVotingAnswerOpen',
  props: {
    myAnswerId: {
      type: Number,
      required: false,
      default: 0,
    },
    index: {
      type: Number,
      required: true,
    },
    choiceData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectedAnswerStyle () {
      return this.myAnswerId === this.choiceData.id ? 'selectedAnswerClass' : ''
    },
  },
  methods: {
    selectAnswer () {
      this.$emit('select-answer', this.choiceData)
    },
  },
}
</script>

<style lang='scss' scoped>
  .voting-answer {
    @apply py-2 flex flex-row justify-start items-center gap-x-4 border rounded-md my-4 pl-2 cursor-pointer;
  }

  .voting-answer:hover {
    border-color: var(--eventMainColor);
  }
  .voting-answer.selectedAnswerClass {
    @apply shadow-lg font-semibold;
    background-color: var(--lightestColor);
    border-color: var(--eventMainColor);
  }

</style>
